<template>
  <div class="container-brochure">
    <div class="container-left-right">
      <div class="borchure brochure-left">
        <div class="font-header mb-3">KL INNOVATION</div>
        <div class="font-description mb-3">
          <strong>
            Articula y sistematiza el proceso macro y micro curricular
          </strong>
          para la gestión de usuarios directivos, académicos y estudiantes
        </div>
        <div class="button-div">
          <b-button
            variant="none"
            class="user-button p-0"
            @click="openKLInnovation()"
          >
            <div class="user-button-left mr-3 pl-3">descargar</div>
            <div class="user-button-right">
              <img
                style="color: white"
                src="../../assets/new-page/PDF_LOGO.svg"
                alt=""
              />
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-left-right">
      <div class="borchure brochure-right">
        <div class="font-header mb-3">KL ASSESSMENT</div>
        <div class="font-description mb-3">
          <strong>
            Articula y sistematiza las evaluaciones de resultados de aprendizaje
            e indicadores de logro
          </strong>
          por asignaturas y sus tributaciones a las competencias del perfil de
          egreso.
        </div>
        <div class="button-div">
          <b-button
            variant="none"
            class="user-button p-0"
            @click="openKLAssesment()"
          >
            <div class="user-button-left mr-3 pl-3">descargar</div>
            <div class="user-button-right">
              <img
                style="color: white"
                src="../../assets/new-page/PDF_LOGO.svg"
                alt=""
              />
            </div>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Brochure",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    openKLAssesment() {
      window.open(
        "https://klearning-backups-configs.s3.sa-east-1.amazonaws.com/internal_usage/FOLLETO_KLASSESMENT.pdf",
        "_blank"
      );
    },
    openKLInnovation() {
      window.open(
        "https://klearning-backups-configs.s3.sa-east-1.amazonaws.com/internal_usage/FOLLETO_KLINNOVATION.pdf",
        "_blank"
      );
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.container-brochure {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
}
.borchure {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 350px;
  background: #006ec8;
  border-radius: 5px;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 10%;
  margin-left: 10%;
  align-items: center;
  justify-content: center;
}
.brochure-left {
  /* background: #006ec8; */
  background: url(../../assets/new-page/Mision-recuadro.svg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.brochure-right {
  /* background: #01adb7; */
  background: url(../../assets/new-page/vision-recuadro.svg) no-repeat center
    center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
}
.font-header {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;

  text-align: center;
  letter-spacing: -0.022em;
  text-transform: uppercase;

  color: #f5f5f5;
}
.font-description {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  width: 50%;
  color: #f5f5f5;
}
.container-left-right {
  display: block;
  width: 50%;
}
.user-button:hover {
  color: white;
  opacity: 0.9;
}
.user-button {
  display: flex;
  flex-direction: row-reverse;
  background-color: #212121;
  width: 14em;
  height: 4em;
  color: white;
  border-radius: 55px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.user-button-left {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}
.user-button-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000000;
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;
}

@media (max-width: 1000px) {
  .container-brochure {
    flex-direction: column;
  }
  .container-left-right {
    width: 100%;
  }
  .borchure {
    margin: 2%;
    height: auto;
  }
  .button-div {
    margin-bottom: 1rem;
  }
  .font-header {
    margin-top: 1rem;
  }
}
</style>